import { graphql, StaticQuery } from "gatsby"
import React from "react"

const Project = () => (
  <StaticQuery
    query={graphql`
      query ProjectsQuery{
        allProjectsJson{
          edges{
            node{
              title,
              description
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <h1>Projects</h1>
        <div>{getProject(data)}</div>
      </>
    )}
  />
)

function getProject(data) {
  const projectItems = []
  data.allProjectsJson.edges.map((skill, index) => projectItems.push(<div className="project"
                                                                      key={index}>{skill.node.description}</div>))
  return projectItems
}

export default Project