import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import Github from "../assets/github.svg"
import Twitter from "../assets/twitter.svg"
import LinkedIn from "../assets/linkedin.svg"
import DevTo from "../assets/devto.svg"

// import Image from "../components/image"
import SEO from "../components/Seo"

const HomePage = () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8"/>
      <title>Javier Borrego</title>
      <link rel="canonical" href="www.javierborrego.dev"/>
    </Helmet>
    <SEO title="Home" text="Javier Borrego"
         keywords={[`javier`, `borrego`, `development`, `fullstack`, `full-stack`, `front`, `back`]}/>
    <div style={{
      flex: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
    }}>
      <div className="landing-page">
        <main>
          <div className="intro-wrapper">
            <div className="intro-name">Hello, I'm Javier!</div>
            <div className="tagline">Full Stack Developer</div>
            <div className="social-icons animate-icons">
              <a target="_blank" rel="noopener noreferrer"
                 href="https://github.com/Ronjea"
              >
                <Github/>
              </a>
              <a target="_blank" rel="noopener noreferrer"
                 href="https://twitter.com/JaviBorregoDev"
              >
                <Twitter/>
              </a>
              <a target="_blank" rel="noopener noreferrer"
                 href="https://www.linkedin.com/in/javierbc/"
              >
                <LinkedIn/>
              </a>
              <a target="_blank"
                 rel="noopener noreferrer"
                 href="https://dev.to/ronjea"
              >
                <DevTo/>
              </a>
            </div>
          </div>
        </main>
      </div>
    </div>
  </Layout>
)

export default HomePage
